#form-file-upload {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0px;
  /* border-style: dashed; */
  border: 1px dashed rgba(0, 0, 0, 0.12);

  background-color: #00000003;
  /* &:hover {
    background-color: black;
  } */
}

.label-file-upload:hover {
  background-color: transparent;
}

.box-drag {
  background-color: black;
}

.upload-button {
  cursor: pointer;

  border: none;
  background-color: transparent;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.file-box {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  background-color: #00000003;
}
